import i18n from 'core/InitialiseThirdParties/i18n';
import { useGetOrderReceipt } from 'hooks/useGetOrderReceipt';
import { computeProductsForCart } from 'pages/Cart/Cart.utils';
import { formatOrderDate } from 'pages/Order/utils';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { BrandRestaurant } from 'services/brand/brand.type';
import {
    CartProduct,
    Discount,
    EntranceFee,
    Grant,
    OrderInformations,
    PriceBreakdown,
} from 'services/order/order.type';

export type OrderReceipt = {
    datetime: string;
    restaurant: BrandRestaurant;
    ticketNumber: string;
    products: CartProduct[];
    currency: string;
    priceBreakdown: PriceBreakdown;
    grants: Grant[];
    fees: EntranceFee[];
    discounts: Discount[];
};

export const useOrderReceiptVM = () => {
    const { orderId } = useParams();
    const { i18n: i18nInstance } = useTranslation();
    const { data, isLoading, error } = useGetOrderReceipt(orderId ?? '');
    const orderReceipt = data ? toOrderReceipt(data, i18nInstance) : null;

    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };

    return { orderReceipt, isLoading, goBack, error };
};

function toOrderReceipt(informations: OrderInformations, i18nInstance: typeof i18n): OrderReceipt {
    const rawData = JSON.parse(informations.cartRawData);
    const products = computeProductsForCart(rawData.enrichedCart, informations.currency);

    return {
        datetime: formatOrderDate(i18nInstance, informations.startDate),
        restaurant: informations.restaurant,
        ticketNumber: informations.ticketNumber ?? '',
        products,
        currency: informations.currency,
        priceBreakdown: computePriceBreakdown(informations),
        grants: informations.grants,
        fees: informations.entranceFees,
        discounts: informations.discounts,
    };
}

function computePriceBreakdown(informations: OrderInformations) {
    const priceBreakdown = {
        totalExclTax: informations.totalPriceWithTaxExcluded,
        totalInclTaxWithDiscount: informations.totalPriceDiscountedWithTaxIncluded,
        totalInclTax: informations.totalPriceWithTaxIncluded,
        totalExclTaxWithDiscount: informations.totalPriceWithTaxExcluded,
        totalTax: informations.totalPriceWithTaxIncluded - informations.totalPriceWithTaxExcluded,
        totalTaxWithDiscount:
            informations.totalPriceDiscountedWithTaxIncluded -
            informations.totalPriceWithTaxExcluded,
        totalInclTaxWithRemise: informations.totalPriceWithTaxIncluded,
        totalExclTaxWithRemise: informations.totalPriceWithTaxExcluded,
        totalTaxWithRemise:
            informations.totalPriceWithTaxIncluded - informations.totalPriceWithTaxExcluded,
    };

    return priceBreakdown;
}
