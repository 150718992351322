import { innovorderApi } from '../index';
import { ApiRequest, ApiResponse } from '../providers/innovorder.types';
import {
    GetOrderByIdPayload,
    GetOrderInformationsPayload,
    GetOrdersHistoryPayload,
    OmnichannelOrder,
    OrderConfirmPayload,
    OrderCreated,
    OrderInformations,
    OrderToCreate,
    OrderToVerify,
    OrderVerified,
    SendAnonymousOrderReceiptPayload,
} from './order.type';

const orderApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        previewOrder: build.mutation<
            OrderVerified,
            ApiRequest<OrderToVerify & { accessToken?: string }>
        >({
            query: ({ accessToken, ...body }) => {
                return {
                    url: `/orders/preview`,
                    method: 'POST',
                    body: {
                        ...body,
                        comment: body.comment || undefined,
                        promocode: body.promocode || undefined,
                    },
                    headers: {
                        ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
                    },
                };
            },
            invalidatesTags: [],
            transformResponse: (response: ApiResponse<OrderVerified>) => ({
                restaurantId: response.data.restaurantId,
                channelId: response.data.channelId,
                consumptionMode: response.data.consumptionMode,
                menuId: response.data.menuId,
                cart: response.data.cart,
                promocode: response.data.promocode,
                comment: response.data.comment,
                recap: response.data.recap,
            }),
            extraOptions: { maxRetries: 3 },
        }),

        createOrder: build.mutation<
            OrderCreated,
            ApiRequest<{
                body: OrderToCreate;
                edenredAccessToken?: string;
                idempotencyKey: string;
                accessToken?: string;
            }>
        >({
            query: ({ body, edenredAccessToken, idempotencyKey, accessToken }) => ({
                url: `/orders`,
                method: 'POST',
                body,
                headers: {
                    'idempotency-key': idempotencyKey,
                    'edenred-auth-token': edenredAccessToken,
                    Authorization: `Bearer ${accessToken}`,
                },
            }),
            invalidatesTags: [],
            transformResponse: (response: ApiResponse<OrderCreated>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),

        confirmOrder: build.mutation<OrderCreated, ApiRequest<OrderConfirmPayload>>({
            query: (body) => ({
                url: `/orders/confirm`,
                method: 'POST',
                body,
            }),
            invalidatesTags: [],
            transformResponse: (response: ApiResponse<OrderCreated>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),

        getOrdersHistory: build.infiniteQuery<
            { omnichannelOrders: OmnichannelOrder[]; count: number },
            GetOrdersHistoryPayload & { accessToken: string },
            {
                offset: number;
                limit: number;
            }
        >({
            infiniteQueryOptions: {
                initialPageParam: {
                    offset: 0,
                    limit: 5,
                },
                getNextPageParam: (lastPage, _allPages, lastPageParam, _allPageParams) => {
                    const nextOffset = lastPageParam.offset + lastPageParam.limit;
                    const remainingItems = lastPage.count - nextOffset;

                    if (remainingItems <= 0) return undefined;

                    return {
                        ...lastPageParam,
                        offset: nextOffset,
                    };
                },
            },
            query: ({ queryArg: { customerId, accessToken }, pageParam }) => ({
                url: `/v1/orders/`,
                method: 'GET',
                params: {
                    customerId,
                    order: '-startDate',
                    offset: pageParam.offset,
                    limit: pageParam.limit,
                },
                headers: { Authorization: `Bearer ${accessToken}` },
            }),
            providesTags: [],
            transformResponse: (
                response: ApiResponse<{ omnichannelOrders: OmnichannelOrder[]; count: number }>,
            ) => response.data,
            extraOptions: { maxRetries: 3 },
        }),

        getOrder: build.query<
            OrderInformations,
            GetOrderInformationsPayload & { accessToken?: string }
        >({
            query: ({ orderUuid, restaurantId, channelId, accessToken }) => ({
                url: `/v1/orders/uuid/${orderUuid}`,
                method: 'GET',
                params: { restaurantId, channelId },
                headers: {
                    ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
                },
            }),
            providesTags: [],
            transformResponse: (response: ApiResponse<OrderInformations>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),

        getOrderById: build.query<OrderInformations, GetOrderByIdPayload & { accessToken: string }>(
            {
                query: ({ orderId, accessToken }) => ({
                    url: `/v1/orders/${orderId}`,
                    method: 'GET',
                    headers: {
                        ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
                    },
                }),
                providesTags: [],
                transformResponse: (response: ApiResponse<OrderInformations>) => response.data,
                extraOptions: { maxRetries: 3 },
            },
        ),

        sendAnonymousOrderReceipt: build.mutation<
            undefined,
            ApiRequest<SendAnonymousOrderReceiptPayload & { accessToken?: string }>
        >({
            query: ({ orderUuid, email, channelId, accessToken }) => ({
                url: `/orders/receipt`,
                method: 'POST',
                body: {
                    email,
                    orderUuid,
                    channelId,
                },
                headers: {
                    ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
                },
            }),
            invalidatesTags: [],
            transformResponse: (response: ApiResponse<undefined>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
    }),
});

export const {
    usePreviewOrderMutation,
    useCreateOrderMutation,
    useConfirmOrderMutation,
    useGetOrdersHistoryInfiniteQuery,
    useGetOrderQuery,
    useGetOrderByIdQuery,
    useSendAnonymousOrderReceiptMutation,
} = orderApi;
